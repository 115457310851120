<script setup lang="ts">
import { useRouter } from "vue-router";
import { cloneDeep } from "lodash-es";
import type { ISubMenuLinks } from "@magnit/unit-navigation/src/VNavigation.types";
import VFooter from "@magnit/unit-footer/src/VFooter.vue";
import {
  CLIENT_LINKS,
  COMPANY_LINKS,
  MANAGEMENT_LINKS,
} from "@magnit/unit-navigation/src/VNavigation.vars";
import { addUtmTags } from "@magnit/unit-navigation/src/VNavigation.utils";

const router = useRouter();
const config = useRuntimeConfig();
const companyLinks = cloneDeep(COMPANY_LINKS);

companyLinks.links.splice(2, 0, {
  label: "Условия использования файлов cookies",
  url: Routes.CookiePolicy,
  onClick: (e: Event) => {
    e.preventDefault();
    router.push(Routes.CookiePolicy);
  },
});

const links: ISubMenuLinks = addUtmTags(
  [CLIENT_LINKS, MANAGEMENT_LINKS, companyLinks],
  config.public.siteUrl,
  "layout",
  "footer",
);
</script>

<template>
  <VFooter project="my-magnit" :links />
</template>
